import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable, timer, concat } from 'rxjs';
import { map, timeout, retryWhen, delayWhen, take, share } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor(private auth: UserService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(res => {
        if (res instanceof HttpResponse && res.body && res.body.data) {
          return res.clone({ body: res.body.data });
        }
        return res;
      })
    );
  }
}
