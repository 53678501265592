import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../../app/services/user.service';
@Injectable({ providedIn: 'root' })
export class RememberMeGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   return this.authenticationService.refreshSession();
  }
}
