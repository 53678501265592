import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../../app/services/user.service';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authenticationService: UserService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authenticationService.getAccessToken();

        if (accessToken) {
            request = request.clone({

                setHeaders: {
                    Authorization: `bearer ${accessToken}`
                }
            });
        }

        return next.handle(request);
    }
}
